@import "../bootstrap/scss/carousel";
.carousel-mask {
  box-shadow: inset 0 0 0 6000px rgba($gray-800, .72);
}
.carousel-indicators {
  li {
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    //rounded indicator
    @include border-radius(50%);
  }
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: $carousel-control-icon-size $carousel-control-icon-size;
}

// Optional Titles
// AXA specific
//

.carousel-title {
  position: absolute;
  top: 50%;
  right: (100% - $carousel-caption-width) * .5;
  bottom: initial;
  left: (100% - $carousel-caption-width) * .5;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;
  transform: translateY(-50%);
}

.carousel-dark {
  .carousel-control-prev-icon {
    background-image: escape-svg($carousel-control-prev-icon-bg-dark);
  }
  .carousel-control-next-icon {
    background-image: escape-svg($carousel-control-next-icon-bg-dark);
  }
  .carousel-caption {
    color: $carousel-caption-color-dark;
  }
  .carousel-title {
    color: $carousel-caption-color-dark;
  }
  .carousel-indicators {
    li {
      background-color: $carousel-indicator-active-bg-dark;
    }
  }
  .carousel-mask {
    box-shadow: inset 0 0 0 6000px rgba($white, .72);
  }
}
