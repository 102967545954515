@import "../bootstrap/scss/navbar";

.navbar-brand {
  min-width: 6rem;
  min-height: 4.5rem;
  background-image: escape-svg($navbar-brand-icon);
  background-repeat: no-repeat;
  background-position: center;
}

.navbar-brand-light {
  min-width: 6rem;
  min-height: 4.5rem;
  background-image: escape-svg($navbar-brand-icon-light);
  background-repeat: no-repeat;
  background-position: center;
}

.navbar-brand-white {
  min-width: 6rem;
  min-height: 4.5rem;
  background-image: escape-svg($navbar-brand-icon-white);
  background-repeat: no-repeat;
  background-position: center;
}

.header-opened{
  position: fixed;
  width: 100%;

  .navbar-mask{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: $gray-mask-800;
  }
}

.navbar-header { // @AXA header with a metaheader
  // box-shadow: inset  0 0 0 1px $gray-200;
  .navbar-expand-xxl {
    box-shadow: none;
  }
  .language-toggler-icon{
    display: inline-block;
    min-width: 1rem;
    min-height: 1rem;
    background-image: escape-svg($navbar-language-toggler-icon);
    background-repeat: no-repeat;
    background-position: center;
  }
  .navbar-metaheader{ // The metaheader is specific to AXA
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: auto;
    color: $gray-400;
    white-space: nowrap;
    background-color: $gray-100;
    border-top: solid 2px $axa-blue-400;
    border-bottom: solid 1px $gray-200;
    .dropdown-toggle::after { // The icon open is blue for the metaheader dropdown
      vertical-align: middle;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2300008f' width='8' height='6' viewBox='0 0 8 6'%3e%3cpath d='M7.06 0.726685L4 3.78002L0.94 0.726685L0 1.66668L4 5.66668L8 1.66668L7.06 0.726685Z'/%3e%3c/svg%3e");
    }
    .dropdown-item { // The paddings are adapted for the metaheader to be larger and even
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
    .dynamic-tab{
      align-items: baseline;
      padding: .5rem 1rem;
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
    }
    .text-dark{
      border-color: $gray-400;
    }
  }

  .nav-menu-responsif{

    &.collapsing{
      height: 0;
      overflow: hidden;
      opacity: 0;
      @include transition($transition-collapse-fast);
    }
  }
  .form-control {
    border-width: 0;
    box-shadow: none;
  }
  .form-search{
    &.collapsing{
      display: none;
    }
  }
  .navbar-header-language {
    width: 100%;
    padding-left: 0;
    margin-top: calc(100vh - 32.5rem);
    margin-right: 0 auto;
    background-color: $gray-100;
    border: 0;
    border-top: solid 2px $axa-blue;
    .btn{
      font-size: $h3-font-size;
    }
  }
  .list-group{
    padding: 0;
    .list-group-item{
      width: 100%;
      padding: 24px 32px 0;
      border: 0;
    }
  }

  .gray-100{
    background-color: $gray-100;
  }
  .border-gray{
    border: $gray-400;
  }
  .navbar-dropdown-content{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1080;
    background-color: $white;
  }
  .meta-tab{
    .btn-link-light{
      flex: 1;
      color: $gray-600;
      &.active{
        color: $gray-800;
        background-color: $white;
      }
    }
  }
  .navbar-header-dropdown{
    border-top: solid 1px $gray-200;
  }

  .header-collapse{
    &.collapsing{
      height: 0;
      overflow: hidden;
      @include transition($transition-collapse-fast);
    }
  }
}

.metaheader-html{
  max-height: 75vh;
  overflow: scroll;
}

.navbar {
  position: unset;
  .nav-link {
    font-size: 14px;
    font-weight: $font-weight-bolder;
    line-height: 18px;
    letter-spacing: $letter-spacing;
  }

  .navbar-dropdown-content {
    .btn {
      font-size: 12px;
      letter-spacing: normal;
      &.accordion-button{
        font-size: 1.25rem;
        font-weight: 600;
        text-transform: none;
        border-bottom: solid 1px $gray-200;
      }
    }
  }
  .accordion-button{
    &.btn:hover,
    .btn:active,
    .btn:focus{
      background-color: $gray-100;
    }
  }

  .btn-search {
    min-width: 4.5rem;
    min-height: 1.5rem;
    margin-left: 0;
    background-image: escape-svg($axa-icon-search);
    background-repeat: no-repeat;
    background-position: center;
  }
}

// Button for toggling the search form when in its collapsed state
.navbar-search-toggler {
  @include font-size($navbar-toggler-font-size);
  @include border-radius($navbar-toggler-border-radius);
  line-height: 1;
  background-color: transparent; // remove default button style
  border: 0;
  border-left: 1px solid $gray-400;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .btn{
    padding: .5rem 1.5rem;

    .navbar-search-toggler-icon {
      background-image: escape-svg($axa-icon-cancel);
    }
    &.collapsed {
      .navbar-search-toggler-icon {
        background-image: escape-svg($axa-icon-search-gray);
      }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      box-shadow: none;
    }
  }
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-search-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

//ad max width arrows right icon and  navbar-nav when in collapse mode
.navbar-nav {
  .nav-link {
    padding-left: 2rem;
    border-color: $navbar-border-color;
    border-style: solid;
    border-width: 0 0 $navbar-border-width;
  }
  .show > .nav-link,
  .active > .nav-link,
  .nav-link.show,
  .nav-link.active {
    background-color: $gray-100;
  }
}

.navbar-toggler-icon {
  width: 1.5rem;
  height: 1rem;
}

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
  border-color: $navbar-border-color;
  border-style: solid;
  border-width: 0 0 $navbar-border-width;

  @each $breakpoint in map-keys($grid-breakpoints) { // lg 1024px
    $next: breakpoint-next($breakpoint, $grid-breakpoints); //xl 1280px
    $infix: breakpoint-infix($next, $grid-breakpoints); // -lg

    // stylelint-disable-next-line scss/selector-no-union-class-name
    &#{$infix} {
      //navbar not expanded > sample for navbar-expand-lg (1024px) => @media (max-width: 1023.98px)...
      @include media-breakpoint-down($next) {
        //hide toggle search button in responsive mode
        .navbar-search-toggler {
          display: none;
        }

        //always show search field in responsive design (even when collapsed in expanded mode)
        .search-collapse {
          display: flex !important; // stylelint-disable-line declaration-no-important
        }
        .input-group {
          width: auto;
        }
        .navbar-collapse {
          border-color: $navbar-border-color;
          border-style: solid;
          border-width: $navbar-border-width 0 0;
          &.header-collapse{
            position: absolute;
            top: $navbar-height;
            right: 0;
            z-index: 1;
            flex-direction: row-reverse;
            width: 470px;
            max-width: 100vw;
            min-height: calc(100vh - 72px);
            background-color: $white;
            border-top: none;
            border-right: 1px solid $gray-200;
          }
          .navbar-header-dropdown {
            height: calc(100vh - 72px);
            overflow-y: auto;
          }
        }

        .form-inline {
          max-width: $navbar-nav-collapsed-max-width;
          padding: 1.5rem;
          border-color: $navbar-border-color;
          border-style: solid;
          border-width: 0 0 $navbar-border-width;
          @include media-breakpoint-up(sm) {
            border-left-width: $navbar-border-width;
          }
        }
        .navbar-nav {
          max-width: $navbar-nav-collapsed-max-width;
          @include media-breakpoint-up(sm) {
            border-color: $navbar-border-color;
            border-style: solid;
            border-width: 0 0 0 $navbar-border-width;
          }
        }
        //update search button in responsive design
        .btn-search {
          display: unset;
          width: unset;
          min-width: 2.5rem;
          min-height: 2.5rem;
          padding: .75rem .875rem;
          margin-left: 2rem;
          background-color: $axa-blue;
          background-image: escape-svg($axa-icon-search-sm);
          background-repeat: no-repeat;
          background-position: center;
          border-color: $axa-blue;
          box-shadow: $btn-box-shadow;
        }
      }
      //navbar expanded > sample for navbar-expand-lg (1024px) => @media (min-width: 1024px)....
      @include media-breakpoint-up($next) {
        box-shadow: $navbar-nav-link-default-box-shadow;

        // Don't display border top in larger screens
        .navbar-header-dropdown{
          margin-left: auto;
          border-top: none;
        }
        .nav-tabs{
          border-bottom: none;
        }

        .btn{
          .navbar-search-toggler-icon{
            background-image: escape-svg($axa-icon-cancel-gray-600);
          }
          &.collapsed{
            .navbar-search-toggler-icon{ background-image: escape-svg($axa-icon-search-gray-600); }
          }
        }

        //hide border in search field when navbar expanded and update font size to large type in search form when navbar expanded
        .form-inline {
          .form-control[type="search"] {
            line-height: $input-line-height-lg;
            @include font-size($input-font-size-lg);
            border-width: 0;
            box-shadow: none;
          }
        }
        //disable transition collapse in navbar-nav anf form-inline (used for search form best behavior in search show/hide form)
        .form-inline,
        .navbar-nav {
          &.collapsing {
            @include transition(none);
          }
        }
        .form-search {
          //fix max height to prevent some design bug in search form
          .input-group {
            max-height: 93.33px;
          }
          //field with full width only in expanded navbar
          &.collapse {
            &.show {
              display: inline;
              width: 100%;
            }
          }
        }
        .navbar-nav {
          width: auto;
          //remove border on nav-link when focus/hover on nav-bar-nav
          &:hover,
          &:focus {
            .nav-link.active,
            .active > .nav-link {
              border-width: 0;
            }
          }

          //re-enable border on active nav-link when focus/hover on nav-bar-nav and focus/hover also on active nav-link
          .nav-link.active:hover,
          .active > .nav-link:hover,
          .nav-link.active:focus,
          .active > .nav-link:focus {
            border-color: $navbar-nav-link-active-border-color;
            border-bottom-width: $navbar-nav-link-active-border-width;
          }

          .nav-link {
            padding: $navbar-nav-link-padding-y $navbar-nav-link-padding-x;
            border-color: transparent;
            border-width: 0;
            border-bottom-width: $navbar-nav-link-active-border-width;
            &:hover,
            &:focus {
              border-color: $navbar-nav-link-active-border-color;
            }
          }

          .show > .nav-link,
          .active > .nav-link,
          .nav-link.show,
          .nav-link.active {
            background-color: unset;
            border-color: $navbar-nav-link-active-border-color;
          }
        }
      }
    }
  }
}

.nav-sep {
  align-self: center;
}

.form-search {
  width: 100% !important;
}

.navbar .btn-search {
  min-width: 4.5rem;
  min-height: 1.5rem;
  margin-left: 0;
  background-image: escape-svg($axa-icon-search);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
  background-color: white;
  border-right: 1px solid #CCCCCC;
}


.result-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #757575;
}
.result-body {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  color: #343c3d;
  font-size: 16px;
  height: 38px;
  line-height: 24px;
}
.result-link {
  font-size: 10px;
  color: #757575;
}
.result {
  line-height: 1em;
  margin-top: 20px;
}
.search-word{
  font-weight: 600;
}
.search-result-block {
  top: 90px;
  position: absolute;
  z-index: 10;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, .2);

}
.search-result-found {
  max-height: 420px;
  overflow-y: auto;
  background-color: white;
}

.search-result-not-found {
  height: 100px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, .2);
  text-align: center;
  height: 100px;
  font-size: 16px;
  line-height: 100px;
  background-color: white;
}




@media screen and (min-width:1024px){

 .search-result-block{
    width: 780px;
    left: calc((100% - 730px)/2 );
  }
}
@media screen and (min-width: 1280px) {
  .search-result-block{
    width: 980px;
    left: calc((100% - 930px)/2);
  }
  }
@media screen and (min-width: 1440px) {
  .search-result-block{
    width: 1150px;
    left: calc((100% - 1100px)/2 );
  }
}
@media screen and (max-width: 1023px) {

  .responsiveNav{
    width: 100%;
    justify-content: end;
    display: inline-flex;
  }
  .navbar{
    width: 100%;
    justify-content:normal ; 
    flex-wrap: unset
  }
    .navbar-search-toggler {
    font-size: 0.875rem;
    border-radius: 0;
    background-color: transparent;
    border: 0;
  }
  .navbar-expand-lg .navbar-search-toggler {
     display: flex !important;
  }
  .navbar-toggler{
    font-size: 0.875rem;
    border-radius: 0;
    background-color: transparent;
    border: 0;
    border-left: 1px solid ; 
    border-color: #CCCCCC!important; 
  }
  .search-result-block {
    width: calc(100% - 210px);
    left: 110px;
    max-height: 465px;
    overflow-y: auto;
    top: 60px;
    position: absolute;
  }
}
@media screen and (max-width: 576px) {
  .search-result-block {
    width: 95%;
    left: 2%;
  }
  
}


