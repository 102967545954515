@import "../bootstrap/scss/breadcrumb";
.breadcrumb {
  a {
    color: $axa-sienna;

    &:hover,
    &:focus {
      color: $axa-sienna-dark;
    }
  }
}
