@import "../bootstrap/scss/card";
//card in collapse accordion
.accordion {
  > .card {
    > .card-header {
      background-color: transparent;
      [data-toggle="collapse"] {
        background-color: $gray-100;
        background-image: escape-svg($axa-icon-arrow-up);
        background-repeat: no-repeat;
        background-position: bottom 50% right 40px;
        &:not(:disabled):not(.disabled) {
          color: $primary;
        }
        &:active {
          box-shadow: none;
        }
        &.btn-block {
          text-align: left;
        }
      }
      .collapsed {
        background-color: transparent;
        background-image: escape-svg($axa-icon-arrow-down);
        background-repeat: no-repeat;
        background-position: bottom 50% right 40px;
        &:not(:disabled):not(.disabled) {
          color: $gray-800;
        }
      }
    }
  }
  .card-body {
    padding-top: 1.5rem;
    padding-right: 2rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
  }
}

.card-subtitle {
  margin-top: 0;
  margin-bottom: $card-spacer-y;
}

// Card image
.card-img-overlay {
  top: auto;
  z-index: 2;
}

.card-img-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  box-shadow: inset 0 0 0 6000px rgba($gray-800, .72);
  @include border-radius($card-inner-border-radius);
}

.card-bg {
  background-color: $gray-200;
}
