.cover-overlay {
  position: absolute;
  right: 0;
  left: 0;
  padding: 1rem;
  z-index: 30;
}

.cover-subtitle {
  font-size: xx-small;
}

.cover-body {
  font-size: medium;
}

.hero-cover-img{
  height: 722px;
  object-fit: cover;
}

.hero-cover-title {
  margin-bottom: .5rem;
  font-size: 48px;
  font-weight: 700;

}

.hero-cover-center {
  margin-right: auto;
  margin-left: auto;
}

.hero-cover-right {
  margin-left: auto;
}

.hero-cover-left {
  margin-right: auto;
}


.hero-cover-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  }
.mask-dark-left{
  background: linear-gradient(90deg, #343C3D 0%, rgba(52, 60, 61, 0.8) 50%, rgba(52, 60, 61, 0) 80%)
}
.mask-dark-right{
  background: linear-gradient(270deg, #343C3D 0%, rgba(52, 60, 61, 0.8) 50%, rgba(52, 60, 61, 0) 80%)
}
.mask-dark-center{
  background:  linear-gradient(0deg, rgba(52, 60, 61, 0.8), rgba(52, 60, 61, 0.8))
}
.mask-light-left{
  background:  linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 80%)
}
.mask-light-right{
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 80%)
}
.mask-light-center{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
}



.hero-cover {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, .125);
}


@include media-breakpoint-down(lg) {
  .cover-overlay {
    width: 90%;
  }
  .hero-cover-img{
    height: 736px;
  }
}

@include media-breakpoint-up(xl) {
  .cover-overlay {
    width: 55%;
  }
}

@include media-breakpoint-only(lg) {
  .cover-overlay {
    width: 75%;
  }
}
