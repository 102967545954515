@import "../bootstrap/scss/root";

:root {
  // Note: Custom variable values only support SassScript inside `#{}`.

  // Colors
  //
  // Generate palettes for AXA colors
  @each $color, $value in $axa-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  @each $color, $value in $axa-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  @each $color, $value in $axaoceans {
    --#{$variable-prefix}axa-ocean-#{$color}: #{$value};
  }

  @each $color, $value in $axaapaches {
    --#{$variable-prefix}axa-apache-#{$color}: #{$value};
  }

  @each $color, $value in $axateals {
    --#{$variable-prefix}axa-teal-#{$color}: #{$value};
  }

  @each $color, $value in $axaviridians {
    --#{$variable-prefix}axa-viridian-#{$color}: #{$value};
  }

  @each $color, $value in $axatoscas {
    --#{$variable-prefix}axa-tosca-#{$color}: #{$value};
  }
  @each $color, $value in $axablues {
    --#{$variable-prefix}axa-blue-#{$color}: #{$value};
  }
  @each $color, $value in $axasiennas {
    --#{$variable-prefix}axa-sienna-#{$color}: #{$value};
  }

  // Fonts

  // Note: Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172

  // AXA Publico font
  --#{$variable-prefix}font-publico: #{inspect($font-family-publico)};

}
