@import "../../bootstrap/scss/utilities/api";

//add background classes for axa main colors from background utilities from v 4.6
// NO MORE USED in BS 5x -> new code map-merge in _variables.scss -> utilities
// @each $color, $value in $axa-colors {
//   @include bg-variant(".bg-#{$color}", $value, true);
// }

//shadows utilities from v 4.6
.shadow-xlg { box-shadow: $box-shadow-xlg; }
.shadow-xxlg { box-shadow: $box-shadow-xxlg; }
//todo re-write !important when css lint no more throwing declaration-no-important

// from text utilities from v 4.6 No more used in 5.1
// .text-muted { margin-top: $text-muted-margin-top; }

//add background classes for axa main colors
// NO MORE USED in BS 5x -> new code map-merge in _variables.scss -> utilities
// @each $color, $value in $axa-colors {
//   .text-#{$color} {
//     color: $value;
//   }
// }
