.footer-container {
	background-color: #4976ba;
	color: white;
	font-family: "Source Sans Pro";
	font-weight: 400;
	font-size: 14px;
	width: 100%;
	margin-bottom: 50px; 


	.footer-body {
		padding: 2rem 2rem 0rem 2rem;
		p{
			font-weight: 600;
			margin-bottom: 16px;
			font-size: 12px;
			text-transform: uppercase;
		}
	
		ul {
			padding-left: 0;
			li {
				list-style: none;
				margin-bottom: 16px;

			}	
			&.icons {
				display: flex;
				justify-content: space-around;
				li {
					display: inline;

					
				img {
					width: 21px;
				}
				 
				}
			}
		}

	}
	 
	.footer {
		padding: 0rem 2rem 0rem 2rem;
		border-top: 1px solid #7698CB; 
		margin-top: 41px;
		p {
			text-align: right;
			margin: 0;
			font-size: 12px;
		}

		.lang-selector { 
			display: inline-flex;
			position: relative;
			border-right: 1px solid #7698CB;
			padding: 13px 30px;
			align-items: center;
			select { 
				font-weight: 600;
				font-size: 12px;
				text-transform: uppercase;
				background-color: #4976ba ;
				color: white;
				border: 0; 
			}
		 
		}
	}
}


.quick-access {

	.quick-container {
		@extend .mt-5;
		@extend .offset-2;
		@extend .col-8;

		p {
			display : inline-flex;
			font-weight: 600;
			margin-bottom: 0;
		}
		.access-direct {
			border-bottom: 1px solid white;
			padding-bottom: 17px;
		}
		.socials {
			padding-top: 17px;
		}
	}

	ul {
		padding-left: 1rem;
		margin-bottom: 0;
		li {
			display: inline-block;
			list-style: none;
			margin-bottom: 0;
			margin-right: 1.3rem;
			position: relative;
			&::after {
				content : "";
				background-color: #7698CB;
				width: 1px;
				height: 17px;
				display: inline-block;
				position: absolute;
				right: -0.7rem; 
				top: 2px;
			}

			&:last-of-type {
				&::after {display: none; }
			}
		
		}
		&.icons {
			li {
				margin-right: 1rem;
				&::after { 
					display: none; 
				}

				img {
					width: 23px;
				}


			
			}
		}	 
	}

}










.footer-body-mobile {
	display: none;
	.accordion, .accordion-item {
		background-color: #4976ba;
		color: white;
		font-family: "Source Sans Pro";
		font-weight: 400;
		ul {
			padding-left: 0;
			li {
				margin-top: 24px;
				list-style: none;
				text-transform: capitalize;
				font-size: 1rem;
			}
		}

		.accordion-body {
			padding: 25px 30px 15px;
		}
	}

	.accordion-button {
		background-color: #3A5E94;
		color: white;
		&.collapsed {
			background-color: #4976ba;
		}
	}
}


@media screen and (max-width:1200px){
	.footer-body {
		display: none;
	}

	.footer-body-mobile {
		display: block;

		ul.icons { 
			padding: 0.8rem 1.8rem;
			display: flex;
			align-content: center; 
			margin-bottom: 0;
			li {
				display: inline;
				margin-right: 40px;
				list-style: none !important;
			} 
		}
	}
	.footer-container {
		.footer{
			padding: 13px 30px;
			margin-top: 0px;
			.lang-selector-container{
				display:none
			}
			p {
				text-align: left;
			}
			.justify-content-end {
				justify-content: start !important;
			}
		}
	} 

	.quick-access {
		display: none;
	}
 
}