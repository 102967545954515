@import "../../bootstrap/scss/mixins/caret";
@mixin caret-down() {
  width: 8px;
  height: 9px;
  background-image: escape-svg($axa-caret-down);
  background-repeat: no-repeat;
}

@mixin caret-up() {
  background-image: escape-svg($axa-caret-down);
  transform: rotate(-180deg);
}

@mixin caret-end() {
  background-image: escape-svg($axa-caret-down);
  transform: rotate(-90deg);
}

@mixin caret-start() {
  width: 8px;
  height: 9px;
  background-image: escape-svg($axa-caret-down);
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
