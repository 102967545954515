@import "../bootstrap/scss/list-group";
.list-group-item {
  padding: $list-group-item-padding;
  // &:not([type="button"]) {
  //   padding: $list-group-item-padding;
  // }

  &.active {
    font-weight: $list-group-active-font-weight;
  }

  &.btn-link-secondary {
    padding: $list-group-item-padding-y $list-group-item-padding-x;
    color: $axa-sienna-400;
    &.active {
      color: #903118;
    }
    &:hover{
      color: #903118;
    }
    &:focus{
      color: #903118;
    }
    &.disabled{
      color: $gray-400;
    }
  }

}

// .list-group-item-action {
//   &[type="button"] {
//     background-color: transparent;
//     &:not(:disabled):not(.disabled):not(:hover):not(:focus) {
//       color: $list-group-button-color;
//       &.active {
//         color: $list-group-button-active-color;
//       }
//     }
//     &::after {
//       float: right;
//       width: 14px;
//       height: 16px;
//       margin-left: .9em;
//       vertical-align: 0;
//       content: "";
//       background-image: escape-svg($list-group-button-icon);
//       background-repeat: no-repeat;
//       background-position-y: center;
//     }
//     &.disabled,
//     &:disabled {
//       &::after {
//         background-image: escape-svg($list-group-button-icon-disabled);
//       }
//     }
//     @include hover-focus() {
//       color: $list-group-button-active-color;
//       &::after {
//         background-image: escape-svg($list-group-button-icon-hover);
//       }
//     }
//   }
// }
