@import "../bootstrap/scss/type";

h1,
h2,
.h1,
.h2 {
  font-weight: $font-weight-bold;
}

h3,
h5,
.h3,
.h5 {
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h4,
.h4 {
  font-weight: $font-weight-normal;
  line-height: $h4-line-height;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h1,
.h1{
  line-height: $h1-line-height;
}
h2,
.h2{
  line-height: $h2-line-height;
}
h3,
.h3{
  line-height: $h3-line-height;
}
h5,
.h5{
  line-height: $h5-line-height;
}
h6,
.h6{
  line-height: $h6-line-height;
}

.lead {
  line-height: $lead-line-height;
}

// Type display classes
@each $display, $line-height in $display-line-heights {
  .display-#{$display} {
    font-family: var(--#{$variable-prefix}font-publico);
    line-height: $line-height;
  }
}

// Blockquotes
.blockquote {
  line-height: $blockquote-line-height;
}
.blockquote-footer {
  line-height: $blockquote-footer-line-height;
}

//
// Emphasis
//

small,
.small {
  line-height: $small-line-height;
}
