.roadmap-container {
  table {
    width: 100%;
    margin-bottom: 11px;

    thead {
      td {
        padding: 16px 35px;
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #343c3d;
        text-align: center;

        &:first-child {
          text-align: left !important;
        }
      }
    }

    tbody {
      tr {
        background-color: #fafafa;
        border-bottom: 2px solid #fff;

        td {
          padding: 16px 35px;

          img {
            margin: auto;
            display: block;
          }

          span {
            font-weight: 600;
          }

          ul {
            padding-left: 1rem;
            margin-top: 3px;

            li {
              color: #757575;
            }
          }
        }
      }
    }
  }

  p {
    text-align: right;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    a {
      color: #d24723;
    }
  }

  .component-status {
    display: inline-flex;
  }

  span.status {
    width: 16px;
    height: 16px;
    margin-left: 0 !important;
    border-radius: 50%;
    margin-right: 4px;
    display: flex;
    transform: translateY(3px);
  }

  span.green {
    background-color: $green;
  }

  span.gray {
    background-color: $gray-400;
  }
}

.roadmap-legand {
  display: flex;

  p {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    display: flex;
    margin-right: 2rem;
  }

  ul {
    list-style: none;
    display: flex;
    padding: 0;

    @media (max-width: 600px) {
      display: block;
    }

    li {
      margin-right: 2rem;
      position: relative;
      margin-bottom: 1rem;

      img {
        vertical-align: sub !important;
      }

      span {
        margin-left: 0.5rem;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 24px;
        background: #cccccc;
        margin-left: 1rem;
        position: absolute;
      }

      &:last-child {
        &::after {
          content: "";
          display: none;
        }
      }

      &:first-child {
        img {
          vertical-align: super !important;
        }
      }
    }
  }

  .component-status {
    display: inline-flex;
  }

  span.status {
    width: 16px;
    height: 16px;
    margin-left: 0 !important;
    border-radius: 50%;
    margin-right: 4px;
    display: flex;
    transform: translateY(3px);
  }

  span.green {
    background-color: $green;
  }

  span.gray {
    background-color: $gray-400;
  }
}
