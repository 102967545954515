@import "../bootstrap/scss/pagination";

.page-item {
  &.active .page-link {
    box-shadow: inset 0 -2px 0 $axa-sienna;
  }
  &.disabled .pagination-control-next-icon {
    background-image: escape-svg($pagination-control-next-icon-disabled);
  }
  &.disabled .pagination-control-prev-icon {
    background-image: escape-svg($pagination-control-prev-icon-disabled);
  }
}
.pagination-control-next-icon,
.pagination-control-prev-icon {
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 100%;
  text-align: center;
  background-repeat: no-repeat;
  background-position-y: 50%;
}

.pagination-control-next-icon {
  background-image: escape-svg($pagination-control-next-icon);
  background-position-x: 100%;
}
.pagination-control-prev-icon {
  background-image: escape-svg($pagination-control-prev-icon);
  background-position-x: 0;
}
