// Bootstrap Loading Skeleton
%loading-skeleton {
    color: transparent;
    appearance: none;
    -webkit-appearance: none;
    background-color: #eee;
    border-color: #eee;

    &::placeholder {
        color: transparent;
    }
}

@keyframes loading-skeleton {
    from {
        opacity: .4;
    }

    to {
        opacity: 1;
    }
}

.loading-skeleton {
    pointer-events: none;
    animation: loading-skeleton 1s infinite alternate;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    img {
        filter: grayscale(100) contrast(0%) brightness(1.8);
    }

    h1, h2, h3, h4, h5, h6,
    p, li,
    .btn,
    label,
    .form-control {
        @extend %loading-skeleton;
    }
}

.loading-skeleton label {
    background-color: slategrey;
}
/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.login-logo {
    height: 20%;
    width: 20%
}

.hr-fade-100 {
    margin: 30px 0;
    height: 1px;
    width: 100%;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, to(transparent), color-stop(0%, black));
}

.hr-fade-90 {
    margin: 30px 0;
    height: 1px;
    width: 90%;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, to(transparent), color-stop(50%, black));
}

.hr-fade-80 {
    margin: 30px 0;
    height: 1px;
    width: 80%;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, to(transparent), color-stop(50%, black));
}


.hr-fade-70 {
    margin: 30px 0;
    height: 1px;
    width: 70%;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, to(transparent), color-stop(50%, black));
}


.hr-fade-60 {
    margin: 30px 0;
    height: 1px;
    width: 60%;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, to(transparent), color-stop(50%, black));
}


.hr-fade-50 {
    margin: 30px 0;
    height: 1px;
    width: 50%;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, to(transparent), color-stop(50%, black));
}

.hr-fade-40 {
    margin: 30px 0;
    height: 1px;
    width: 40%;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, to(transparent), color-stop(50%, black));
}

.hr-fade-30 {
    margin: 30px 0;
    height: 1px;
    width: 30%;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, to(transparent), color-stop(50%, black));
}

.hr-fade-20 {
    margin: 30px 0;
    height: 1px;
    width: 30%;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, to(transparent), color-stop(50%, black));
}