@font-face {
    font-family: 'PublicoHeadlineAxa'; /*a name to be used later*/
    src: url("./../font/PublicoHeadline-Bold-Web.ttf"); /*URL to font*/
}

html, body {
    margin: 0;
    height: 100%;
}


.border-axa-header {
    border-bottom: 1px solid #dee2e6 !important;
    border-top: 2px solid var(--primary-axa-blue-400-base, #00008F);
}

.language-selector-font {
    font: var( --desktop-utility-navigation-13-px, 600 13px/18px "Source Sans Pro", sans-serif );
}

.language-selector-button {
    height: 72px
}

.language-selector-options {
    width: 91px !important;
    min-width: 91px !important;
    height: 46px;
    border-style: solid;
    border-color: var(--greyscale-grey-400-cccccc, #cccccc);
    border-width: 1px;
}

.language-selector-options * {
    width: 91px !important;
    min-width: 91px !important;
}

.bg-dark-langugage-selector {
    background-color: var(--greyscale-grey-200-f-0-f-0-f-0, #f0f0f0) !important;
}

.axa-box-shadow-hero {
    box-shadow: var( --basic-emphasis-l-box-shadow, 0px 4px 20px 0px rgba(0, 0, 0, 0.1) );
}

.title {
    text-align: left;
    position: relative;
    align-self: stretch;
    color: var(--greyscale-grey-800343-c-3-d, #343C3D);
    font-size: 40px;
    font-family: PublicoHeadlineAxa;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 1px;
    overflow: clip;
}

.title-accordion {
    color: var(--greyscale-grey-800343-c-3-d, #343C3D);
    font-size: 24px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    overflow: clip;
}
@media (max-width: 767px) {
    .title-accordion {
        line-height: 24px;
        font-size: 18px;
    }
}

.title-accordion-alt {
    color: var(--greyscale-grey-800343-c-3-d, #343C3D);
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    line-height: 28px;
    letter-spacing: 0.1px;
}

.overflow-clip {
    overflow: clip !important;
}

.axa-hero-overlay-list-check {
    color: #027180;
}

.axa-hero-overlay-list-font {
    color: var(--greyscale-grey-800343-c-3-d, #343C3D);
    font-size: 16px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.bg-grey-100 {
    background: var(--greyscale-grey-100-fafafa, #FAFAFA) !important;
}

.bg-grey-200 {
    background: var(--greyscale-grey-100-fafafa, #F0F0F0) !important;
}

.title-sub-text {
    color: var(--greyscale-grey-800343-c-3-d, #343C3D);
    text-align: center;
    font-size: 18px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.axa-service-card-title.align-left,
.axa-input-label.align-left,
.axa-newproject-second-title.align-left,
.axa-under-text.align-left,
.title-sub-text.align-left {
    text-align: left;
}

.border-bottom-axa-accordion {
    border-bottom: 2px solid #f0f0f0 !important;
}

.axa-accordion-header-bg {
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.axa-tertialy-viridian-green-bg {
    background-color: #E9F2EF !important;
}

.axa-center-text {
    margin: auto;
    text-align: center;
}

.axa-start-text {
    margin: auto;
    text-align: start;
}

.axa-center-start {
    margin: auto;
    text-align: start
}

.axa-step-lines {
    justify-content: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}


@media (min-width: 1024px) {
    .axa-step-lines::before {
        position: absolute;
        content: "";
        border-bottom: 2px dotted #5b7b73;
        width: 50%;
        top: 32px;
        left: 2px;
        z-index: 0;
    }

    .axa-step-lines.blue::before {
        border-bottom: 2px dotted #4976BA;
    }

    .axa-step-lines::after {
        position: absolute;
        content: "";
        border-bottom: 2px dotted #5b7b73;
        width: 50%;
        top: 32px;
        left: 50%;
        z-index: 0;
    }

    .axa-step-lines.blue::after {
        border-bottom: 2px dotted #4976BA;
    }

    .axa-step-lines.axa-step-first-child::before {
        content: none;
    }

    .axa-step-lines.axa-step-last-child::after {
        content: none;
    }
}

.axa-step {
    background: var( --tertiary-viridian-green-5-b-7-b-73-viridian-400-base, #5b7b73 );
    border-radius: 48px;
    padding: 14px 16px 14px 16px;
    width: 64px;
    height: 64px;
}

@media (max-width: 767px) {
    .axa-step {
        padding: 7px 14px 7px 14px;
        width: 48px;
        height: 48px;
    }
}

.axa-step.blue {
    background: var(--tertiary-ocean-400, #4976BA);
}

.axa-step-circle-content {
    color: var(--tertiary-viridian-green-5-b-7-b-73-viridian-100, #e9f2ef);
    text-align: center;
    font: var( --desktop-headings-heading-4-28-px, 700 28px/36px "Source Sans Pro", sans-serif );
    position: relative;
    align-self: stretch;
    flex: 1;
    z-index: 1;
}
@media (max-width: 767px) {
    .axa-step-circle-content {
        font: var( --desktop-headings-heading-4-22-px, 700 22px/36px "Source Sans Pro", sans-serif );
    }
}

.axa-step-title-text-content {
    padding: 0px 16px 0px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.axa-step-title {
    color: var(--tertiary-viridian-green-5-b-7-b-73-viridian-700, #181f1e);
    text-align: center;
    font: var( --desktop-headings-heading-4-28-px, 700 28px/36px "Source Sans Pro", sans-serif );
    position: relative;
    align-self: stretch;
}

@media (max-width: 767px) {
    .axa-step-title {
        font: var( --desktop-headings-heading-4-18-px, 700 18px/36px "Source Sans Pro", sans-serif );
        line-height: 24px
    }
}

#root {
    position: relative;
    min-height: 100vh;
}

.axa-content-wrap {
    padding-bottom: 141px; /* Footer height */
    height: 100%
}

.axa-footer {
    background: #4976BA;
    padding-right: 85px;
    padding-left: 85px;
    height: 56px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.axa-footer-title {
    color: var(--greyscale-white-ffffff, #FFF);
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.axa-footer-link {
    color: var(--greyscale-white-ffffff, #FFF);
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.axa-service-category-card {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    text-align: center;
    margin-bottom: 24px;
}

.axa-card-header {
    color: var(--greyscale-grey-800343-c-3-d, #343C3D);
    text-align: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1px;
}


.axa-subcategory-logo {
    width: 100px;
    height: 100px;
}


@media (max-width: 1024px) {
    .axa-desktop-footer {
        display: none !important;
    }

    .axa-footer {
        padding-left: 0px;
        padding-right: 0px;
    }
}

//mobile
@media (max-width: 767px) {
    .axa-accordion-row {
        padding-top: 32px;
        padding-bottom: 24px;
    }

    .axa-card-header {
        font-size: 14px;
        line-height: 20px;
    }

    .axa-service-category-card {
        height: 205px;
        padding: 16px 8px 16px;
    }

    .axa-subcategory-logo {
        margin-bottom: 12px;
    }

    .title-accordion-alt {
        color: var(--greyscale-grey-800343-c-3-d, #343C3D);
        font-family: Source Sans Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.1px;
    }

    .title {
        color: var(--greyscale-grey-800343-c-3-d, #343C3D);
        font-size: 24px;
        line-height: 32px;
    }

    .axa-mobile-hidden {
        display: none !important;
    }

    .axa-offcanvas-tabcontent-padding {
        padding: 0 20px 0 20px;
    }

    .axa-subcat-card-padding {
        padding: 0 20px 48px 20px;
    }

    .axa-project-card {
        max-width: 335px;
    }

    .axa-service-card-title {
        color: var(--greyscale-grey-800343-c-3-d, var(--greys-grey-800, #343C3D));
        font-family: PublicoHeadlineAxa;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.2px;
    }

    .axa-service-card-diveder {
        margin-right: -1.3rem;
        margin-left: -1.3rem;
    }

    .axa-newproject-second-title {
        color: var(--greyscale-grey-800343-c-3-d, var(--greys-grey-800, #343C3D));
        font-family: Source Sans Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.1px;
    }
}

//desktop
@media (min-width: 1024px) {
    .axa-mobile-footer {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .axa-accordion-row {
        padding-top: 64px;
        padding-bottom: 56px;
    }

    .axa-card-header {
        font-size: 24px;
        line-height: 32px;
    }

    .axa-service-category-card {
        height: 280px;
        padding: 32px 16px;
    }

    .axa-subcategory-logo {
        margin-bottom: 32px;
    }

    .axa-offcanvas-tabcontent-padding {
        padding: 0 115px 0 115px;
    }

    .axa-subcat-card-padding {
        padding: 0 40px 80px 40px;
    }

    .axa-project-card {
        max-width: 730px;
        padding: 32px 0px 64px 0px
    }

    .axa-service-card-title {
        color: var(--text-paragraph-text-headings, #343C3D);
        font-family: PublicoHeadlineAxa;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.2px;
    }

    .axa-service-card-diveder {
        margin-right: -2.5rem;
        margin-left: -2.5rem;
    }

    .axa-newproject-second-title {
        color: var(--greyscale-grey-800343-c-3-d, var(--greys-grey-800, #343C3D));
        font-family: Source Sans Pro;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.1px;
    }


    .axa-service-radio-label {
        max-width: 210px
    }
}

.axa-body {
    overflow-y: scroll;
}

.axa-mobile-footer-item-border {
    border-bottom: 1px solid rgba(255, 255, 255, .2) !important;
}

.axa-mobile-footer-subitem {
    background: #405c94 !important;
    color: var(--greyscale-white-ffffff, #FFF);
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.axa-mobile-footer-accordion {
    background-color: #4976BA !important;
}

.axa-bg-footer {
    background-color: #4976BA
}

.axa-bg-subcat {
    background-color: #027180 !important;
}

.axa-service-title {
    color: var(--greys-white, #FFF);
    text-align: center;
    font-family: PublicoHeadlineAxa;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 1px;
}

@media (max-width: 767px) {
    .axa-service-title {
        color: var(--greys-white, #FFF);
        font-size: 24px;
        line-height: 32px;
    }
}

.axa-service-overview-text {
    color: var(--text-interaction-text-primary-action, #FFF);
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: start;
}

.axa-nav {
    list-style-type: none;
    margin: 0;
    padding-bottom: 8px;
    overflow-x: auto;
    white-space: nowrap !important;
    background-color : #FFFFFF;
}

.axa-nav::-webkit-scrollbar,
.axa-offcanvas-nav::-webkit-scrollbar {
    background: #FFFFFF;
    width: 5px;
    height: 8px;
}

.axa-nav::-webkit-scrollbar-thumb,
.axa-offcanvas-nav::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #4976BA;
}

.axa-nav::-webkit-scrollbar-track {
    background: #CCCCCC;
    border-radius: 0px;
    margin: 0px 20px 0 20px;
}

.axa-offcanvas-nav::-webkit-scrollbar-track {
    background: #CCCCCC;
    border-radius: 0px;
}


.axa-tab {
    border: 0;
    background-color: #FAFAFA;
    padding: 16px;
    align-items: center;
    border-bottom: 1px solid var(--greys-grey-300, #E5E5E5);
    border-left: 1px solid var(--greys-grey-300, #E5E5E5);
    display: inline-block;
    //Font
    color: var(--primary-axa-blue-400, #00008F);
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.axa-tab.axa-active-tab {
    background-color: #FFFFFF;
    border-bottom: 0;
    z-index: 10;
    //Font
    color: var(--greys-grey-800, #343C3D);
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
}


.axa-center-content {
    display: flow;
    align-content: center !important;
}

.axa-close-offcanvas-text {
    color: var(--primary-axa-blue-400, #00008F);
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.axa-close-offcanvas-button {
    background-color: transparent;
    border: 0;
}

.axa-stepbar-number {
    background: #FFFFFF;
    padding: 4px 8.5px 4px 8.5px;
    border: 1px solid;
    border-radius: 200px;
    color: #00008f;
    font: var( --body-caption-12-px-bold, 700 12px/16px "Source Sans Pro", sans-serif );
}

.axa-stepbar-number.active {
    background: #00008f;
    border: 1px solid rgb(0,0,0,0);
    border-radius: 200px;
    color: var(--greys-white, #ffffff);
    font: var( --body-caption-12-px-bold, 700 12px/16px "Source Sans Pro", sans-serif );
}

.axa-steps-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    white-space: nowrap !important;
}

.axa-step-list-item {
    display: inline-block;
    padding: 1rem;
}

.axa-steps-list .axa-step-list-item {
    border: 0;
    border-bottom: 3px solid rgb(0, 0, 0, 0);
}

.axa-steps-list .active,
.axa-steps-list .axa-step-list-item:hover {
    border-bottom: 3px solid rgb(0, 0, 143, 100);
}

.axa-stepsbar-text {
    color: var(--primary-axa-blue-400, #00008F);
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.axa-stepsbar-text.past {
    font-weight: 700;
}

.axa-offcanvas-nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    white-space: nowrap !important;
}

.axa-offcanvas-nav-item {
    display: inline-block;
    padding: 1rem;
    color: var(--primary-axa-blue-400, #00008F);
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.axa-offcanvas-nav .axa-offcanvas-nav-item {
    border: 0;
    border-bottom: 3px solid rgb(0, 0, 0, 0);
}

.axa-offcanvas-nav .active,
.axa-offcanvas-nav .axa-offcanvas-nav-item:hover {
    border-bottom: 3px solid rgb(0, 0, 143, 100);
    font-weight: 700;
}

.axa-card-shadow {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.axa-btn-forward {
    color: var(--text-interaction-text-disabled, #5F5F5F) !important;
    border-bottom: 3px solid var(--border-interaction-button-border-disabled, #E5E5E5) !important;
    background: var(--surface-interaction-surface-action-disabled, #F0F0F0) !important;
    font-family: Source Sans Pro;
    font-style: normal;
}

.axa-service-radio-label {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.axa-under-text {
    color: var(--greyscale-grey-7005-f-5-f-5-f, var(--greys-grey-700, #5F5F5F));
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.axa-service-card-diveder {
    border: solid 1px #E5E5E5;
}

.axa-success-box {
    border-left: 4px solid #138636;
    background-color: #F0F0F0;
}

.axa-success-box-header {
    color: var(--text-paragraph-text-headings, #343C3D);
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.axa-success-box-text {
    color: var(--greys-grey-800, #343C3D);
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.axa-warning-box {
    border-left: 4px solid #FFBC11;
    background-color: #F0F0F0;
}

.axa-error-box {
    border-left: 4px solid #C91432;
    background-color: #F0F0F0;
}

.axa-error-box li {
    color: var(--primary-axa-blue-400-base, var(--primary-axa-blue-400, #00008F));
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: underline;
}

.axa-header-SansPro-24-700 {
    color: var(--greyscale-grey-800343-c-3-d, var(--greys-grey-800, #343C3D));
    text-align: right;
    font-family: Source Sans Pro;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.1px;
}

.axa-input-label {
    color: var(--text-paragraph-text-body, #343C3D);
    /* Label/Large - 16px/Semibold */
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.1px;
}

.axa-input-file-div {
    border: 1px dashed var(--greys-grey-400, #CCC);
    background: var(--greys-grey-100, #FAFAFA);
}

.axa-files-table-header {
    background: var(--tertiary-teal-400, #027180);
    color: var(--greys-white, #FFF);
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    height: 56px;
    vertical-align: middle;
}

.axa-files-table-cell {
    vertical-align: middle;
}

.axa-files-table-body {
    color: var(--text-paragraph-text-body, #343C3D);
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.axa-project-steps-border-color.active {
    border-color: #D24723 !important;
}

.axa-project-steps-border-color.active:hover {
    border-color: #D24723 !important;
}

.axa-project-steps-border-color:hover {
    border-color: rgba(0,0,0,0) !important;
}

.axa-confirmation-title {
    color: var(--greyscale-grey-7005-f-5-f-5-f, var(--greys-grey-700, #5F5F5F));
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.axa-confirmation-text {
    color: var(--greyscale-grey-800343-c-3-d, var(--greys-grey-800, #343C3D));
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.axa-confirmation-email {
    color: var(--greyscale-grey-800343-c-3-d, var(--greys-grey-800, #343C3D));
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.axa-bg-success1 {
    background: var(--tertiary-ocean-100, #EBF3FF);
    padding: 64px 180px 56px 180px !important;
}

.axa-header-success1 {
    color: var(--text-paragraph-text-headings, #343C3D);
    text-align: center;
    font-family: PublicoHeadlineAxa;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 1px;
}

.axa-text-success1 {
    color: var(--text-paragraph-text-headings, #343C3D);
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.axa-bg-success2 {
    background: var(--tertiary-teal-100, #EBFDFF);
    padding: 64px 85px 80px 85px !important;
}

.axa-header-success2 {
    color: var(--greys-grey-800, #343C3D);
    font-family: PublicoHeadlineAxa;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.2px;
}

.axa-text-success2 {
    color: var(--greys-grey-800, #343C3D);
    font-family: Source Sans Pro;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.1px;
}

.axa-bg-success3 {
    background: var(--tertiary-ocean-200, #D6E7FF);
    padding: 64px 85px 80px 85px !important;
}

.axa-endrow-success {
    margin-bottom: -85px !important;
}

@media (max-width: 767px) {
    .axa-bg-success1 {
        padding: 32px 20px 40px 20px !important;
    }

    .axa-bg-success2 {
        padding: 32px 20px 48px 20px !important;
    }

    .axa-bg-success3 {
        padding: 32px 20px 48px 20px !important;
    }
}

.axa-check-20 {
    height: 20px;
    width: 20px;
}

.axa-provider-info-card {
    border: 1px solid #F0F0F0;
    border-left: 4px solid;
    border-left-color: #00008f;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bg-yellow {
    background-color: yellow
}

.rounded-start-pill {
    border-bottom-left-radius: 50px !important;
    border-top-left-radius: 50px !important;
}

.axa-break-word {
    white-space: pre-line !important;
    overflow-wrap: break-word !important;
    -ms-word-break: break-word !important;
    word-break: break-word !important;
}

.axa-bg-viridian {
    background-color: #5B7B73 !important;
}

.axa-bg-viridian a {
    color: #FDFDEB !important;
    text-decoration: underline;
}

.axa-text-underline {
    text-decoration: underline;
}

.axa-hero-card-padding {
    padding: 40px 40px 40px 40px;
}

@media (max-width: 767px) {
    .axa-hero-card-padding {
        padding: 32px 24px 32px 24px;
    }
}

.cover-overlay-axa {
    position: relative;
    max-width: 540px;
    margin: -285px 0 0 0;
}

@media(max-width:1023px) {
    .cover-overlay-axa {
        margin: -100px 0 0 0;
    }
}

@media(max-width:767px) {
    .cover-overlay-axa {
        margin: -70px 0 0 0;
    }
}

.axa-vertical-align-content {
    display: flex;
    align-items: center;
}

.axa-accordion-button-padding {
    padding: 24px 32px 24px 32px !important;
}

@media(max-width:767px) {
    .axa-accordion-button-padding {
        padding: 24px !important;
    }
}

.axa-flex-even {
    flex: 1 1 0 !important;
}

@media(max-width:767px) {
    .axa-order-mobile-1 {
        order: 1 !important;
    }
    .axa-order-mobile-2 {
        order: 2 !important;
    }
    .axa-order-mobile-3 {
        order: 3 !important;
    }
}

.axa-service-representer-image {
    max-height: 250px
}

@media(max-width:767px) {
    .axa-service-representer-image {
        max-height: 120px
    }
}

.axa-bottom-border {
    border-bottom: 1px solid #CCCCCC
}

@media(max-width:767px) {
    .axa-sm-p-b-1 {
        padding-bottom: 8px
    }
}

@media(max-width:767px) {
    .axa-sm-p-x-3 {
        padding: 0 16px 0 16px;
    }
}

.axa-warning-modal-padding {
    padding: 0 24px 0 24px !important;
}

@media(max-width:767px) {
    .axa-warning-modal-padding {
        padding: 0 4px 0 4px !important;
    }
}



@media(min-width:767px) {
    .axa-service-selection-button-height {
        height: 90px;
    }
}